import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/hospitalizacion.png'
import { Link } from 'react-router-dom'
import '../styles.scss'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Hospitalización</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Las 9 habitaciones con las que cuenta hospitalización son áreas
            diseñadas para prestar cuidados básicos y especializados, de manera
            integral y con altos estándares de calidad, generando una
            experiencia de eficiencia y seguridad en la atención, tanto al
            paciente como a su familia.
          </Text>
          <Text>
            El equipo de trabajo está constituido por los profesionales médicos
            de las diferentes especialidades de acuerdo a la necesidad del
            paciente, un médico hospitalario, en cada turno tanto diurno como
            nocturno tres enfermeras profesionales.
          </Text>
          <Text>
            Promovemos y facilitamos el acompañamiento permanente de los
            pacientes.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
