import React, { useState } from 'react'
import { connect } from 'react-redux'
import { create } from '../../../actions'
import Title from '../../../components/Title'
import Subtitle from '../../../components/Subtitle'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Swal from 'sweetalert2'
import './styles.scss'

const Contact = props => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [message, setMessage] = useState()
  const [phone, setPhone] = useState()

  const handleSubmit = () => {
    const data = {
      name,
      email,
      comment: message,
      phone,
    }

    props
      .create('contact', 'CREATE_CONTACT', data)
      .then(() => {
        document.getElementById('formContact').reset()
      })
      .then(() => {
        Swal.fire({
          title: 'Enviado con éxito',
          text: 'Gracias por contactarnos, pronto nos comunicaremos contigo.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar',
        })
      })
  }

  return (
    <div className="contact" id="contact">
      <div className="contact__container">
        <Title>Contacto</Title>
        <div className="contact__grid">
          <div className="contact__info">
            <Subtitle>Dirección</Subtitle>
            <a
              href="https://www.google.com/maps/place/Hospital+san+francisco+de+asis/@19.4191093,-98.1446836,21z/data=!3m1!5s0x85d020252c4ca8df:0x7947b4ea96b1ef8c!4m5!3m4!1s0x85d0211bb717f1af:0x9c5daf8ad32bd146!8m2!3d19.419177!4d-98.1445425"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text>
                Calle 5 de Febrero No. 913
                <br />
                Col. Centro C.P. 90300
                <br />
                Apizaco, Tlaxcala
              </Text>
            </a>
            <Subtitle>Teléfonos</Subtitle>
            <Text>
              <a className="link" href="tel:+522414171917">
                (241) 417 1917
              </a>
              <br />
              <a className="link" href="tel:+522414172848">
                (241) 417 2848
              </a>
            </Text>
            <Subtitle>Correo Electrónico</Subtitle>
            <a className="link" href="mailto:sanfranciscorecepcion4@gmail.com">
              <Text>sanfranciscorecepcion4@gmail.com</Text>
            </a>
          </div>
          <div className="contact__form">
            <Subtitle>Escríbenos tus dudas o sugerencias</Subtitle>
            <form id="formContact">
              <div>
                <input
                  onInput={e => setName(e.target.value)}
                  placeholder="Nombre"
                />
                <input
                  onInput={e => setPhone(e.target.value)}
                  placeholder="Teléfono"
                />
                <input
                  onInput={e => setEmail(e.target.value)}
                  placeholder="Correo electrónico"
                />
              </div>
              <div
                style={{
                  marginLeft: '25px',
                  width: window.screen.width <= 600 ? '93%' : '40%',
                }}
              >
                <textarea
                  placeholder="Comentarios"
                  onChange={e => setMessage(e.target.value)}
                ></textarea>
                <Button onClick={handleSubmit}>Enviar</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  create,
}

export default connect(null, mapDispatchToProps)(Contact)
