const initialState = {
  banners: [
    {
      id: 1,
      title: 'LOS MEJORES MÉDICOS DE LA REGIÓN A SOLO UN CLICK',
      subtitle: `Nuestro Hospital cuenta con un amplio personal médico calificado en las diferentes áreas y especialidades de la medicina, consulta nuestros servicios y no dejes para mañana lo que te puedas checar hoy.`,
      button2: 'Servicios',
    },
  ],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_BANNERS':
      return {
        ...state,
        banners: action.payload.data,
      }
    case 'GET_BRANDS':
      return {
        ...state,
        brands: action.payload.data,
      }
    case 'CREATE_PRE_DATE':
      return {
        ...state,
        preDate: action.payload,
      }
    default:
      return state
  }
}
