import React from 'react'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import Subtitle from '../../../components/Subtitle'
import icoMision from '../../../assets/static/IcoMision.svg'
import icoVision from '../../../assets/static/IcoVision.svg'
import icoPrivacidad from '../../../assets/static/IcoPrivacidad.svg'
import img1 from '../../../assets/static/1.png'
import img2 from '../../../assets/static/2.jpeg'
import img3 from '../../../assets/static/3.jpeg'
import img4 from '../../../assets/static/4.png'
import './styles.scss'

import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div className="about" id="about">
      <div className="about__container">
        <Title>Nosotros</Title>
        <Text>
          El Hospital San Francisco de Asís abrió sus puertas en la ciudad de
          Apizaco Tlaxcala el día 22 de octubre de 1993 como una institución
          dedicada a la atención y prevención de la salud.
        </Text>
        <div className="about__bar">
          <div className="about__icon">
            <img src={icoMision} alt="" />
            <div className="about__text">
              <Subtitle>Misión</Subtitle>
              <Text>
                Brindar atención médica y hospitalaria con el equipo médico y
                personal capacitado para dar una atención con calidad, calidez y
                trato humano a todo el público en general.
              </Text>
            </div>
          </div>
          <div className="about__icon">
            <img src={icoVision} alt="" />
            <div className="about__text">
              <Subtitle>visión</Subtitle>
              <Text>
                Ser una institución de vanguardia en el estado que cuente con
                los mejores médicos especialistas, personal eficiente y
                capacitado que pueda proporcionar atención esmerada contando con
                equipo e instrumental que nos facilite desarrollar mejor
                nuestras actividades.
              </Text>
            </div>
          </div>
          <div className="about__icon">
            <img src={icoPrivacidad} alt="" />
            <div className="about__text">
              <Subtitle>Objetivos</Subtitle>
              <Text>
                Proporcionar a nuestros pacientes la atención médica integral de
                prevención y curación a través de nuestros diferentes servicios.
              </Text>
            </div>
          </div>
        </div>
        <div className="about__grid">
          <Link to="/facilities">
            <div
              className="about__gridItem"
              style={{ backgroundImage: `url('${img1}')` }}
            >
              <div className="about__gridItem__bar">instalaciones</div>
            </div>
          </Link>
          <Link to="/laboratory">
            <div
              className="about__gridItem"
              style={{ backgroundImage: `url('${img2}')` }}
            >
              <div className="about__gridItem__bar">Análisis clínicos</div>
            </div>
          </Link>
          <div
            className="about__gridItem"
            style={{ backgroundImage: `url('${img3}')` }}
          >
            <div className="about__gridItem__bar">área quirúrgica</div>
          </div>
          <div
            className="about__gridItem"
            style={{ backgroundImage: `url('${img4}')` }}
          >
            <div className="about__gridItem__bar">terapia física</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
