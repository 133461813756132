import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/terapiaFisica.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">
          {' '}
          Terapia física y rehabilitación.
        </Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            El servicio de Medicina Física y Rehabilitación proporciona atención
            médica de rehabilitación integral a pacientes discapacitados o con
            padecimientos potencialmente discapacitantes del sistema
            neuromusculoesquelético y cardiopulmonar.
          </Text>
          <Text>
            Es una especialidad médica independiente dedicada a promover el
            funcionamiento físico, cognitivo, actividades, participación y a
            modificar factores personales y ambientales. Por consiguiente, es
            responsable de la prevención, diagnóstico y tratamientos de la
            rehabilitación de personas de todas las edades que presentan alguna
            discapacidad como consecuencia de una enfermedad o traumatismo así
            como sus comorbilidades.
          </Text>
          <Text>
            Brindar atención médica de rehabilitación con oportunidad y calidad
            con el fin de restaurar la función y la reintegración a la
            comunidad.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
