import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/bioimpedancia.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Bioimpedancia</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Mantenerte saludable es fundamental. ¿Te imaginas tener un análisis
            de tu cuerpo en solo 24 segundos? Es posible hacerlo de forma no
            invasiva y muy sencilla. ¡Conoce nuestro nuevo equipo de tecnología
            alemana! Disponible en Equilíbrate, donde tu salud y la de tu
            familia es lo más importante.
          </Text>
          <Text>
            Agenda tu cita de bioimpedancia+tratamiento nutricio con app y
            agenda electrónica, por un precio increíble y logra grandes cambios
            en tu estado de salud.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
