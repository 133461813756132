import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/nebu.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Nebulizaciones</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Otra forma de administrar la medicación de forma inhalada es por
            medio de nebulizadores. Su uso se recomienda en pacientes que
            precisan inhalar fármacos que solo existen en esa presentación y no
            son capaces de utilizar otros dispositivos.
          </Text>
          <Text>
            La eficacia de la nebulización depende de muchos factores: la
            sustancia a nebulizar, la anatomía de la vías aéreas, la técnica de
            inhalación del paciente, el sistema de inhalación y el mantenimiento
            del mismo (tipo de nebulizador, tubos, filtros, etc).
          </Text>
          <Text>
            El volumen de llenado inicial por lo general debe ser entre 4-5 ml,
            salvo que el nebulizador este diseñado específicamente para ser
            usado con un volumen menor. La solución suele hacerse con suero
            salino, pero también se puede utilizar agua destilada, combinar
            ambas, según la tolerancia del paciente. Volúmenes menores pueden
            aumentar la viscosidad de la solución y dificultad que su
            nebulizador. El tiempo de nebulización depende del volumen y la
            densidad de la solución.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
