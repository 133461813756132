import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/farmacia.png'
import '../styles.scss'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Farmacia</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La farmacia del Hospital San Francisco de Asís se encuentra abierta
            de lunes a viernes de 9 am a 6 pm y los días sábados de 9 am a 12
            pm, brindando atención al público en general, a pacientes de
            nuestros médicos especialistas, así como también a pacientes de
            seguros y empresas con las cuales tenemos convenio.
          </Text>
          <Text>
            La farmacia es el servicio de atención médica que se encarga de
            elegir, almacenar y dispensar medicamentos y productos sanitarios,
            asesorando a profesionales de la salud y pacientes sobre su uso
            seguro, efectivo y eficiente.
          </Text>
          <Text>
            En otras palabras, toda la medicación que recibimos cuando estamos
            hospitalizados, en urgencias, en consulta o que deben recoger los
            pacientes externos en el hospital, sale por las puertas de este
            servicio.
          </Text>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
