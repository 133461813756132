import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getAll } from '../../../actions'
import { preDate } from '../actions'
import Swal from 'sweetalert2'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade } from 'swiper'
import './styles.scss'
import Button from '../../../components/Button'
import Input from '../../../components/Input'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/effect-fade/effect-fade.scss'

import imagePrueba from '../../../assets/static/banner.png'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade])

const CarouselMain = props => {
  const { banners, doctors } = props
  const [doctor, setDoctor] = useState(0)
  const [email, setEmail] = useState(0)
  const [phone, setPhone] = useState(0)
  const history = useHistory()

  useEffect(() => {
    // props.getAll('banners', 'GET_BANNERS')
    props.getAll('doctors', 'GET_DOCTORS')
    // eslint-disable-next-line
  }, [])

  const animation = event => {
    const textContainer = event[0].childNodes[0].childNodes[0].childNodes[0]
    const newTextContainer = textContainer.cloneNode(true)
    textContainer.parentNode.replaceChild(newTextContainer, textContainer)

    const floatContainer = event[0].childNodes[0].childNodes[0].childNodes[1]
    const newfloatContainer = floatContainer.cloneNode(true)
    floatContainer.parentNode.replaceChild(newfloatContainer, floatContainer)
  }

  const handleDoctorDate = () => {
    if (doctor !== 0 && doctor !== '0') {
      props.preDate({ email, phone })
      history.push(`/profile?id=${doctor}`)
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Cuidado',
        text: 'Primero seleccione uno de nuestros doctores',
      })
    }
  }

  return (
    <div className="carouselMain">
      <Swiper
        className="carouselMain__container"
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 8000, disableOnInteraction: true }}
        effect="fade"
        watchSlidesVisibility="true"
        onSlideChange={swiper => {
          animation(swiper.visibleSlides)
        }}
      >
        {banners.map(banner => (
          <SwiperSlide key={banner.id} className="carouselMain__item">
            <div
              className="banner"
              style={{
                backgroundImage: `linear-gradient(
                  rgba(0, 0, 0, .4),
                  rgba(0, 0, 0, .4)
                ), url(${imagePrueba})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="banner__container">
                <div className="banner__left">
                  <div className="banner__title flip-in-hor-bottom">
                    {banner.title}
                  </div>
                  <div className="banner__subtitle flip-in-hor-top">
                    {banner.subtitle}
                  </div>
                  <Link to="/services">
                    <Button className="button slide-in-blurred-right --white">
                      {banner.button2}
                    </Button>
                  </Link>
                </div>
                <div className="banner__right">
                  <div className="banner__float fade-in">
                    <h3>Agendar Consulta</h3>
                    <Input
                      className="inputGroup --white"
                      title="E-mail"
                      type="email"
                      name="email"
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Input
                      className="inputGroup --white"
                      title="Teléfono"
                      type="tel"
                      name="phone"
                      onChange={e => setPhone(e.target.value)}
                    />
                    <div className="inputGroup --white">
                      <label htmlFor="doctors">
                        <span>Doctores</span>
                        <select
                          name="doctors"
                          onChange={e => setDoctor(e.target.value)}
                        >
                          <option value="0">Seleccionar</option>
                          {doctors
                            ? doctors.map(doctor => (
                                <option
                                  value={doctor.user_id}
                                  key={doctor.user_id}
                                >
                                  {doctor.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </label>
                    </div>
                    <Button
                      onClick={() => handleDoctorDate()}
                      className="button --secondary"
                    >
                      ¡Agendar ahora!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    banners: state.reducerHome.banners,
    doctors: state.reducerDoctors.doctors,
  }
}

const mapDispatchToProps = {
  getAll,
  preDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarouselMain)
