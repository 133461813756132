import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/Electromiografiae.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">
          (EMG) Electromiografía de las 4 extremidades.
        </Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La electromiografía (EMG) es un procedimiento de diagnóstico que se
            utiliza para evaluar la salud de los músculos y las células
            nerviosas que los controlan (neuronas motoras). Los resultados de la
            electromiografía pueden revelar una disfunción nerviosa, una
            disfunción muscular o problemas con la transmisión de señales de
            nervios a músculos.
          </Text>
          <Text>
            Las neuronas motoras transmiten señales eléctricas que hacen que los
            músculos se contraigan. En la electromiografía se utilizan
            dispositivos diminutos denominados electrodos para traducir estas
            señales en gráficos, sonidos o valores numéricos que después
            interpreta un especialista.
          </Text>
          <Text>
            Durante una electromiografía con aguja, un electrodo de aguja que se
            introduce directamente en un músculo registra la actividad eléctrica
            en ese músculo.
          </Text>
          <Text>
            En un estudio de conducción nerviosa, otra parte de la
            electromiografía, se utilizan los electrodos adheridos a la piel
            (electrodos de superficie) para medir la velocidad y la intensidad
            de las señales que se desplazan entre dos o más puntos.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
