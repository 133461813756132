import axios from 'axios'
import Swal from 'sweetalert2'

const API_KEY_TOKEN =
  '77a5f9501bfc62140ff0402fdc9bd9cdf60c269fd9c909ee43971b3885a4ac69'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      Swal.fire({
        title: 'Sesión Expirada',
        text: 'Su sesión ha expirado',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then(result => {
        if (result.isConfirmed) {
          logOut()
          sessionStorage.clear()
          window.location.href = '/login'
        }
      })
    } else {
      return Promise.reject(error)
    }
  }
)

export const setUser = (payload, redirectionUrl) => async dispatch => {
  const { user, password } = payload
  try {
    const res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}/api/auth/sing-in`,
      auth: {
        username: user,
        password,
      },
      data: {
        apiKeyToken: API_KEY_TOKEN,
      },
    })

    sessionStorage.setItem('token', res.data.token)
    sessionStorage.setItem('user', res.data.user.user)
    sessionStorage.setItem('name', res.data.user.name)
    sessionStorage.setItem('id', res.data.user.id)
    sessionStorage.setItem('role', res.data.user.role)

    window.location.href = redirectionUrl

    dispatch({
      type: 'LOGIN_REQUEST',
    })
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Usuario o contraseña incorrectos',
    })
  }
}

export const logOut = () => ({
  type: 'LOG_OUT',
})

//CRUD

export const getAll = (endPoint, typeAction) => async dispatch => {
  const storedJwt = sessionStorage.getItem('token')
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API}/api/${endPoint}`,
      headers: { Authorization: `Bearer ${storedJwt}` },
      method: 'get',
    })
    dispatch({
      type: typeAction,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const get = (endPoint, typeAction) => async dispatch => {
  const storedJwt = sessionStorage.getItem('token')
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API}/api/${endPoint}`,
      headers: { Authorization: `Bearer ${storedJwt}` },
      method: 'get',
    })
    dispatch({
      type: typeAction,
      payload: res.data.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const create = (endPoint, typeAction, data) => async dispatch => {
  const storedJwt = sessionStorage.getItem('token')

  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API}/api/${endPoint}`,
      headers: { Authorization: `Bearer ${storedJwt}` },
      method: 'post',
      data: data,
    })
    dispatch({
      type: typeAction,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const update = (endpoint, typeAction, data) => async dispatch => {
  const storedJwt = sessionStorage.getItem('token')

  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API}/api/${endpoint}`,
      headers: { Authorization: `Bearer ${storedJwt}` },
      method: 'put',
      data: data,
    })
    console.log(res)
    dispatch({
      type: typeAction,
      payload: data,
      patchPayload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const deleted = (endpoint, typeAction) => async dispatch => {
  const storedJwt = sessionStorage.getItem('token')

  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API}/api/${endpoint}`,
      headers: { Authorization: `Bearer ${storedJwt}` },
      method: 'delete',
    })
    dispatch({
      type: typeAction,
      payload: res.data.data,
    })
  } catch (error) {
    console.log(error)
  }
}
