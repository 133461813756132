import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/urgencias.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Urgenicas y Ratos X 24 hrs.</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Nuestro servicio de urgencias y rayos ¨X¨ se encuentran a su
            disposición los 365 días del año las 24 horas.
          </Text>
          <Text>
            El servicio inicial de urgencias da la atención médica para aquel
            paciente que presenta daños a la salud, que ponen en riesgo la
            función orgánica, psicológica o la vida y que por ello requiere
            atención médica inmediata y habitualmente es multidisciplinaria.
          </Text>
          <Text>
            El proceso que se lleva a cabo cuando un paciente ingresa al
            servicio de urgencias, es el siguiente: Donde se le toman los signos
            vitales, se pregunta al paciente cuáles son sus síntomas y se
            realiza el primer diagnóstico, se determina el área más apropiada
            para el tratamiento del paciente y de ser necesario se traslada a la
            sala de urgencias donde se continuará con el tratamiento, el
            ¨TRIAGE¨ disminuye la congestión en las áreas de urgencia y permite
            una evaluación continua a los pacientes.
          </Text>
          <Text>
            Con el presente diagnóstico se obtiene un panorama global de la
            situación actual y con ello establecer las estrategias necesarias
            para resolver problemáticas, pero sobre todo, potencializar nuestra
            fortaleza como equipo de trabajo.
          </Text>
          <Text>
            Nuestro servicio de imagenología y rayos ¨X¨ cuenta con imagen
            digital impresa, así como contamos con personal altamente
            calificado, siendo este servicio un plus en cualquier urgencia que
            lo requiera.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
