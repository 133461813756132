import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/controlmaternidad.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Control de maternidad</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            El control del embarazo o control prenatal es el seguimiento que se
            realiza durante todo el periodo de gestación. Las revisiones con el
            especialista ginecólogo deben ser mensuales durante los siete
            primeros meses del embarazo y más frecuentes cuando se acerca la
            fecha estimada de parto. En cada cita se realizan pruebas de rutina
            para verificar el buen desarrollo de la gestación, mientras algunos
            reconocimientos se realizan solo en embarazos de riesgo.
          </Text>
          <Text>
            El control del embarazo se realiza porque es necesario para el
            bienestar de la madre y del bebé. Durante el embarazo pueden darse
            una serie de complicaciones para el bebé y la propia madre que
            pueden prevenirse y evitarse mediante un seguimiento de la
            evolución. Así, el control del embarazo se realiza para una correcta
            prevención de las complicaciones, aconsejar a la madre y controlar
            el estado del feto.
          </Text>
          <Text>
            El control del embarazo consiste en una serie de revisiones y
            visitas sucesivas al médico para llevar a cabo la observación de la
            evolución del bebé.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
