import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getAll } from '../../../actions'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import './styles.scss'
import { FaPhoneAlt } from 'react-icons/fa'
import img from '../../../assets/static/demo.png'
import Loading from '../../../components/Loading'

const Doctors = props => {
  const { getAll, doctors } = props

  useEffect(() => {
    getAll('doctors', 'GET_DOCTORS')
    // eslint-disable-next-line
  }, [])

  if (doctors) {
    return (
      <div className="doctors" id="doctors">
        <div className="doctors__container">
          <Title>Médicos</Title>
          <Swiper
            className="doctors__slider"
            spaceBetween={50}
            slidesPerView={window.screen.width <= 600 ? 1 : 3}
            slidesPerColumn={1}
            navigation={true}
            loop={true}
            autoplay={true}
          >
            {doctors
              ? doctors.map(doctor => (
                  <SwiperSlide className="doctors__item" key={doctor.id}>
                    <div className="doctors__itemProfile">
                      <img
                        className="doctors__image"
                        src={
                          doctor.image !== null
                            ? `${process.env.REACT_APP_FILES}/docs/doctors/${doctor.image}`
                            : img
                        }
                        alt=""
                      />
                      <div className="doctors__itemText">
                        <h5 className="doctors__name">{doctor.name}</h5>
                        <Text className="text doctors__specialty">
                          {doctor.specialty}
                        </Text>
                        <h5 className="doctors__phone">
                          <FaPhoneAlt />
                          {doctor.phone}
                        </h5>
                      </div>
                    </div>
                    <Text className="text doctors__description">
                      {doctor.description}
                    </Text>
                    <Link to={`profile?id=${doctor.user_id}`}>
                      <Button>Ver perfil</Button>
                    </Link>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    doctors: state.reducerDoctors.doctors,
  }
}

const mapDispatchToProps = {
  getAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Doctors)
