import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/3.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Quirófano y sala de expulsión</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            El Quirófano es un área dentro del Hospital donde se opera, o se
            interviene quirúrgicamente, a los pacientes. Funciona las 24 horas
            del día, los siete días de la semana y ahí se atienden
            intervenciones programadas o de urgencia, con internación o
            ambulatorias.
          </Text>
          <Text>
            “Nuestra principal función una vez que el paciente ingresa al
            Quirófano es darle toda la atención, cuidados y contención que
            necesite durante el transcurso de la cirugía y en las primeras horas
            de su recuperación”.
          </Text>
          <Text>
            Cuentan con equipamiento de alta tecnología que se reemplaza y
            actualiza según los avances y las nuevas técnicas quirúrgicas.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
