import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/terapiaFisica.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Cirugia de cuello uterino</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La Conización (cono cervical, electrocirugía) es la extirpación en
            forma “cónica” de la parte externa del cuello del útero o exocérvix
            (base del cono) y una parte interna correspondiente al canal o
            endocérvix (vértice del cono).
          </Text>
          <Text>
            El procedimiento es indoloro ya que se administra Anestesia local
            (En el propio cuello del útero), es la técnica más utilizada, o
            Anestesia regional o general (peridural o intradural osedación con
            anestesia endovenosa), y en esta técnica se requiere de un médico
            Anestesiólogo.
          </Text>
          <Text>
            La única molestia durante el procedimiento puede ser la colocación
            del espejo o separador vaginal, que es necesario para la
            visualización del cuello cervical.
          </Text>
          <Text>
            Análisis para detección Las pruebas de detección pueden ayudar a
            detectar el cáncer de cuello uterino y las células precancerosas que
            un día pueden convertirse en cáncer de cuello uterino. La mayoría de
            las pautas sugieren comenzar a realizar exámenes de detección para
            el cáncer cervical y cambios precancerosos a la edad de 21 años.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
