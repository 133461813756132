import { combineReducers } from 'redux'
import reducerApp from './reducer'
import reducerHome from '../pages/Home/reducers'
import reducerDoctors from '../pages/Profile/reducer'
import reducerTests from '../pages/Laboratory/reducer'

const reducer = combineReducers({
  reducerApp,
  reducerHome,
  reducerDoctors,
  reducerTests,
})

export default reducer
