const initialState = {
  tests: [],
  letters: [
    { id: 'todos' },
    { id: 'a' },
    { id: 'b' },
    { id: 'c' },
    { id: 'd' },
    { id: 'e' },
    { id: 'f' },
    { id: 'g' },
    { id: 'h' },
    { id: 'i' },
    { id: 'j' },
    { id: 'k' },
    { id: 'l' },
    { id: 'm' },
    { id: 'n' },
    { id: 'ñ' },
    { id: 'o' },
    { id: 'p' },
    { id: 'q' },
    { id: 'r' },
    { id: 's' },
    { id: 't' },
    { id: 'u' },
    { id: 'v' },
    { id: 'w' },
    { id: 'x' },
    { id: 'y' },
    { id: 'z' },
  ],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_TESTS':
      return {
        ...state,
        tests: action.payload.data,
      }
    case 'GET_TESTS_REQUEST':
      return {
        ...state,
        testsRequest: action.payload.data,
      }
    case 'GET_TEST':
      return {
        ...state,
        test: action.payload,
      }
    default:
      return state
  }
}
