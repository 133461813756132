import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/cafeteria.jpeg'
import '../styles.scss'


const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Cafetería</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Nuestro servicio de cafetería está a su disposición los 365 días del
            año desde las 8:00 Hrs a 18:00 Hrs, para que su espera dentro
            nuestras instalaciones sea aún más amena podrá disfrutar de una de
            las vistas más espectaculares de nuestra ciudad rielera.
          </Text>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
