import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/biopsias-min.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'


const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Biopsias</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Una biopsia es un procedimiento que se realiza para extraer una
            pequeña muestra de tejido o de células del cuerpo para su análisis
            en un laboratorio. Si experimentas determinados signos y síntomas o
            si el médico identifica algo que sea motivo de preocupación, es
            posible que debas someterte a una biopsia para determinar si tienes
            cáncer o alguna otra enfermedad.
          </Text>
          <Text>
            Mientras que las pruebas de diagnóstico por imágenes, como las
            radiografías, son útiles para detectar masas o zonas anómalas, no
            pueden diferenciar por sí solas las células cancerosas de las que no
            lo son. En el caso de la mayoría de los tipos de cáncer, la única
            forma de dar un diagnóstico definitivo es realizar una biopsia que
            extrae células para un examen más exhaustivo.
          </Text>
          <Text>
            Estos son algunos de los diferentes tipos de biopsia que se utilizan
            para dar un diagnóstico de cáncer.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
