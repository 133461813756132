import './styles.scss'
const Modal = props => {
  const { requeriments = [], className, onClick } = props
  return (
    <div className={`modal ${className}`} onClick={onClick}>
      <div className="modal__container">
        <div className="modal__title">Requisitos</div>
        <ul className="modal__list">
          {requeriments.map(required => (
            <li className="modal__item" key={required.id}>
              {required.request}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Modal
