import React from 'react'
import Title from '../../components/Title'
import { Link } from 'react-router-dom'
import banner from '../../assets/static/services/BannerNueServ.png'
import img1 from '../../assets/static/services/urgencias.png'
import img2 from '../../assets/static/services/farmacia.png'
import img3 from '../../assets/static/services/hospitalizacion.png'
import img4 from '../../assets/static/3.jpeg'
import img5 from '../../assets/static/services/medicinadeltrabajo.png'
import img6 from '../../assets/static/services/habitaciones.png'
import img7 from '../../assets/static/services/nebu.png'
import img8 from '../../assets/static/services/incubadoras.png'
import img9 from '../../assets/static/services/terapiaFisica.jpeg'
import img10 from '../../assets/static/services/Endoscopia.png'
import img11 from '../../assets/static/services/cafeteria.jpeg'
import img12 from '../../assets/static/services/colposcopia.jpeg'
import img13 from '../../assets/static/services/terapiaFisica.png'
import img14 from '../../assets/static/services/controlmaternidad.jpeg'
import img15 from '../../assets/static/services/papanicolaou.jpeg'
import img16 from '../../assets/static/services/biopsias-min.jpeg'
import img17 from '../../assets/static/services/planificacionfamiliar.jpeg'
import img18 from '../../assets/static/services/ultrasonidoObs.jpeg'
import img19 from '../../assets/static/services/detecciondecancer.jpeg'
import img20 from '../../assets/static/services/patologiadeMama.jpeg'
import img21 from '../../assets/static/services/Encefalograma.jpeg'
import img22 from '../../assets/static/services/MonitoreoHolter.jpeg'
import img23 from '../../assets/static/services/Pruebadeesfuerzo.jpeg'
import img24 from '../../assets/static/services/monitoreoambulat.jpeg'
import img25 from '../../assets/static/services/Electromiografiae.jpeg'
import img26 from '../../assets/static/services/bioimpedancia.png'
import img27 from '../../assets/static/services/psicologia-clinica.png'
import './styles.scss'

const Services = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Servicios</Title>
      </div>
      <div className="servicesPage__container">
        <Link className="servicesPage__item" to="services/urgencias">
          <img className="servicesPage__image" src={img1} alt="" />
          <h5 className="servicesPage__subtitle">
            Urgencias y Rayos X 24 hrs.
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/farmacia">
          <img className="servicesPage__image" src={img2} alt="" />
          <h5 className="servicesPage__subtitle">Farmacia</h5>
        </Link>

        <Link className="servicesPage__item" to="services/hospitalizacion">
          <img className="servicesPage__image" src={img3} alt="" />
          <h5 className="servicesPage__subtitle">Hospitalización</h5>
        </Link>

        <Link className="servicesPage__item" to="services/quirofano">
          <img className="servicesPage__image" src={img4} alt="" />
          <h5 className="servicesPage__subtitle">
            Quirófano y sala de expulsión
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/medicina">
          <img className="servicesPage__image" src={img5} alt="" />
          <h5 className="servicesPage__subtitle">Medicina del trabajo.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/habitaciones">
          <img className="servicesPage__image" src={img6} alt="" />
          <h5 className="servicesPage__subtitle">Habitaciones.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/nebulizaciones">
          <img className="servicesPage__image" src={img7} alt="" />
          <h5 className="servicesPage__subtitle">Nebulizaciones</h5>
        </Link>

        <Link className="servicesPage__item" to="services/incubadoras">
          <img className="servicesPage__image" src={img8} alt="" />
          <h5 className="servicesPage__subtitle">Incubadoras y cuneros.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/terapia">
          <img className="servicesPage__image" src={img9} alt="" />
          <h5 className="servicesPage__subtitle">
            Terapia física y rehabilitación.
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/endoscopia">
          <img className="servicesPage__image" src={img10} alt="" />
          <h5 className="servicesPage__subtitle">Endoscopía.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/cafeteria">
          <img className="servicesPage__image" src={img11} alt="" />
          <h5 className="servicesPage__subtitle">Cafetería.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/colposcopia">
          <img className="servicesPage__image" src={img12} alt="" />
          <h5 className="servicesPage__subtitle">Colposcopía.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/cuello-uterino">
          <img className="servicesPage__image" src={img13} alt="" />
          <h5 className="servicesPage__subtitle">Cirugía de cuello uterino.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/maternidad">
          <img className="servicesPage__image" src={img14} alt="" />
          <h5 className="servicesPage__subtitle">Control de maternidad.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/papanicolaou">
          <img className="servicesPage__image" src={img15} alt="" />
          <h5 className="servicesPage__subtitle">Papanicolaou.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/biopsias">
          <img className="servicesPage__image" src={img16} alt="" />
          <h5 className="servicesPage__subtitle">Biopsias.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/familiar">
          <img className="servicesPage__image" src={img17} alt="" />
          <h5 className="servicesPage__subtitle">Planificación familiar.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/ultrasonido">
          <img className="servicesPage__image" src={img18} alt="" />
          <h5 className="servicesPage__subtitle">Ultrasonido obstétrico.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/cervicouterino">
          <img className="servicesPage__image" src={img19} alt="" />
          <h5 className="servicesPage__subtitle">
            Detección de cáncer cervicouterino.
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/patologia">
          <img className="servicesPage__image" src={img20} alt="" />
          <h5 className="servicesPage__subtitle">Patología de mama.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/encefalograma">
          <img className="servicesPage__image" src={img21} alt="" />
          <h5 className="servicesPage__subtitle">
            Encefalograma digital y mapeo cerebral.
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/holter">
          <img className="servicesPage__image" src={img22} alt="" />
          <h5 className="servicesPage__subtitle">
            Monitoreo Holter de 24 Hrs.
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/prueba-esfuerzo">
          <img className="servicesPage__image" src={img23} alt="" />
          <h5 className="servicesPage__subtitle">Prueba de esfuerzo.</h5>
        </Link>

        <Link className="servicesPage__item" to="services/mapa">
          <img className="servicesPage__image" src={img24} alt="" />
          <h5 className="servicesPage__subtitle">
            Mapa (Monitoreo ambulatorio de presión arterial.
          </h5>
        </Link>

        <Link className="servicesPage__item" to="services/emg">
          <img className="servicesPage__image" src={img25} alt="" />
          <h5 className="servicesPage__subtitle">
            (EMG) Electromiografía de las 4 extremidades.
          </h5>
        </Link>
        <Link className="servicesPage__item" to="services/bio">
          <img className="servicesPage__image" src={img26} alt="" />
          <h5 className="servicesPage__subtitle">Bioimpedancia</h5>
        </Link>
        <Link className="servicesPage__item" to="services/psicologia-clinica">
          <img className="servicesPage__image" src={img27} alt="" />
          <h5 className="servicesPage__subtitle">Psicología clínica</h5>
        </Link>
      </div>
    </div>
  )
}

export default Services
