import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import logo from '../../assets/static/logo.svg'
import './styles.scss'

const Header = () => {
  const [togleBar, setTogle] = useState('')

  const toggleAction = () => {
    setTogle(togleBar === '' ? 'active' : '')
  }

  const location = useLocation()

  if (
    location.pathname === '/profile' ||
    location.pathname === '/profile/details'
  ) {
    return null
  } else {
    return (
      <nav>
        <div className="nav">
          <Link to="/" className="nav__brand">
            <img src={logo} className="nav__logo" alt="" />
          </Link>
          <div className="nav__menuMovil" onClick={toggleAction}>
            <AiOutlineMenu />
          </div>
          <ul className={`nav__menu ${togleBar}`}>
            <li className="nav__item" onClick={toggleAction}>
              <AiOutlineClose />
            </li>

            <li className="nav__item">
              <HashLink
                smooth
                to="/#inicio"
                className="nav__link"
                onClick={toggleAction}
              >
                Inicio
              </HashLink>
            </li>
            <li className="nav__item">
              <HashLink
                smooth
                to="/#about"
                className="nav__link"
                onClick={toggleAction}
              >
                Nosotros
              </HashLink>
            </li>
            <li className="nav__item">
              <HashLink
                smooth
                to="/#services"
                className="nav__link"
                onClick={toggleAction}
              >
                Servicios
              </HashLink>
            </li>
            <li className="nav__item">
              <HashLink
                smooth
                to="/#specialties"
                className="nav__link"
                onClick={toggleAction}
              >
                Especialidades
              </HashLink>
            </li>
            {/* <li className="nav__item">
              <HashLink smooth to="/#about" className="nav__link" onClick={toggleAction}>
                Convenios
              </HashLink>
            </li> */}
            <li className="nav__item">
              <HashLink
                smooth
                to="/#contact"
                className="nav__link"
                onClick={toggleAction}
              >
                Contacto
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default Header
