import React from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../assets/static/logo.svg'
import logoFacebook from '../../assets/static/IcoFace.svg'
import './styles.scss'

const Footer = () => {
  const location = useLocation()

  if (
    location.pathname === '/profile' ||
    location.pathname === '/profile/details'
  ) {
    return null
  } else {
    return (
      <footer>
        <div className="footer">
          <img src={logo} alt="Hospital San Fracisco de Asis Logo" />
          <p>TODOS LOS DERECHOS RESERVADOS 2021</p>
          <div className="footer__icons">
            <a
              href="https://www.facebook.com/Hospital-San-Francisco-de-Asis-HSFA-1053095804759267/"
              target="blank"
            >
              <img src={logoFacebook} alt="ícono facebook" />
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
