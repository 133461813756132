const initialState = {
  doctorServices: [
    { name: 'Obesidad', id: 1 },
    { name: 'Diabetes', id: 2 },
    { name: 'Hipertensión', id: 3 },
  ],
  doctorDates: [{ date: '2021-06-18 15:00:00', id: 1 }],
  doctorImages: [
    {
      id: 1,
      url: 'https://previews.123rf.com/images/nyul/nyul1503/nyul150300029/37409833-doctor-que-consulta-con-el-paciente-en-la-habitaci%C3%B3n-del-doctor-la-presentaci%C3%B3n-de-los-resultados-en-el-or.jpg',
    },
    {
      id: 2,
      url: 'https://previews.123rf.com/images/nyul/nyul1503/nyul150300029/37409833-doctor-que-consulta-con-el-paciente-en-la-habitaci%C3%B3n-del-doctor-la-presentaci%C3%B3n-de-los-resultados-en-el-or.jpg',
    },
    {
      id: 3,
      url: 'https://previews.123rf.com/images/nyul/nyul1503/nyul150300029/37409833-doctor-que-consulta-con-el-paciente-en-la-habitaci%C3%B3n-del-doctor-la-presentaci%C3%B3n-de-los-resultados-en-el-or.jpg',
    },
    {
      id: 4,
      url: 'https://previews.123rf.com/images/nyul/nyul1503/nyul150300029/37409833-doctor-que-consulta-con-el-paciente-en-la-habitaci%C3%B3n-del-doctor-la-presentaci%C3%B3n-de-los-resultados-en-el-or.jpg',
    },
    {
      id: 5,
      url: 'https://previews.123rf.com/images/nyul/nyul1503/nyul150300029/37409833-doctor-que-consulta-con-el-paciente-en-la-habitaci%C3%B3n-del-doctor-la-presentaci%C3%B3n-de-los-resultados-en-el-or.jpg',
    },
  ],
  doctorQA: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_DOCTORS':
      return {
        ...state,
        doctors: action.payload.data,
      }
    case 'GET_DOCTOR':
      return {
        ...state,
        doctor: action.payload,
      }
    case 'GET_DOCTOR_IMAGES':
      return {
        ...state,
        doctorImages: action.payload.data,
      }
    case 'GET_DOCTOR_SERVICES':
      return {
        ...state,
        doctorServices: action.payload.data,
      }
    case 'GET_DOCTOR_DATES':
      return {
        ...state,
        doctorDates: action.payload.data,
      }
    case 'GET_DOCTOR_QA':
      return {
        ...state,
        doctorQA: action.payload.data,
      }
    case 'GET_DOCTOR_SCHEDULES':
      return {
        ...state,
        doctorSchedules: action.payload.data,
      }
    case 'GET_DOCTOR_INACTIVE_DAYS':
      return {
        ...state,
        doctorsInactiveDays: action.payload.data,
      }
    case 'GET_DATES':
      return {
        ...state,
        dates: action.payload.data,
      }
    default:
      return state
  }
}
