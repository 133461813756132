import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getAll, get, create } from '../../actions'
import { TiArrowBack } from 'react-icons/ti'
import { FaFacebook } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { AiOutlineLink } from 'react-icons/ai'
import './styles.scss'
import ico1 from '../../assets/static/IcoPerfil.svg'
import ico2 from '../../assets/static/IcoConsultorio.svg'
import ico3 from '../../assets/static/IcoPreguntas.svg'
import Button from '../../components/Button'
import Text from '../../components/Text'
import moment from 'moment-timezone'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid'
import esLocale from '@fullcalendar/core/locales/es'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

import 'moment/locale/es'
import logo from '../../assets/static/logo.png'
import img from '../../assets/static/demo.png'
import doctoralia from '../../assets/static/doctoralia.png'
import Swal from 'sweetalert2'

import Loading from '../../components/Loading'

moment.tz.setDefault('America/Mexico_City')

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Profile = props => {
  let query = useQuery()
  const {
    doctor,
    doctorServices,
    doctorDates,
    doctorImages,
    doctorQA,
    get,
    getAll,
    doctorSchedules,
    doctorsInactiveDays,
    dates,
    preDate,
  } = props
  const [color, setColor] = useState('#2A58AD')
  const [color2, setColor2] = useState('#2A58AD')
  const [color3, setColor3] = useState('#2A58AD')
  const [border, setBorder] = useState('#2A58AD')
  const [border2, setBorder2] = useState('#2A58AD')
  const [border3, setBorder3] = useState('#2A58AD')
  const [view, setView] = useState(0)
  const [scheduleDate, setScheduleDate] = useState('')
  const [name, setName] = useState()
  const [phone, setPhone] = useState()
  const [mail, setMail] = useState()
  const refCalendar = useRef(null)

  useEffect(() => {
    get(`doctors/${query.get('id')}`, 'GET_DOCTOR')
      .then(() => {
        getAll(`doctors/services/${query.get('id')}`, 'GET_DOCTOR_SERVICES')
      })
      .then(() => {
        getAll(`doctors/dates/${query.get('id')}`, 'GET_DOCTOR_DATES')
      })
      .then(() => {
        getAll(`doctors/images/${query.get('id')}`, 'GET_DOCTOR_IMAGES')
      })
      .then(() => {
        getAll(`doctors/qa/${query.get('id')}`, 'GET_DOCTOR_QA')
      })
      .then(() => {
        getAll(`doctors/qa/${query.get('id')}`, 'GET_DOCTOR_QA')
      })
      .then(() => {
        getAll(`schedules/${query.get('id')}`, 'GET_DOCTOR_SCHEDULES')
      })
      .then(() => {
        getAll(`schedules/days/${query.get('id')}`, 'GET_DOCTOR_INACTIVE_DAYS')
      })
      .then(() => {
        getAll(`dates`, 'GET_DATES')
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (doctor) {
      setColor(doctor.color1)
      setColor2(doctor.color1)
      setColor3(doctor.color1)
      setBorder(doctor.color1)
      setBorder2(doctor.color1)
      setBorder3(doctor.color1)
    }
  }, [doctor])

  useEffect(() => {
    if (preDate) {
      setPhone(preDate.phone)
      setMail(preDate.email)
    }
  }, [preDate])

  if (
    doctor &&
    doctorServices &&
    doctorDates &&
    doctorImages &&
    doctorQA &&
    doctorSchedules &&
    doctorsInactiveDays &&
    dates
  ) {
    timeGridPlugin.views.timeGridWeek.duration = { days: 1 }

    const handleDateClick = arg => {
      setScheduleDate(
        moment.utc(arg.el.fcSeg.start).format('YYYY-MM-DD HH:mm:ss')
      )
    }

    const events = doctorsInactiveDays.map(day => {
      return {
        title: 'N/A',
        date: moment(day.date).format('YYYY-MM-DD'),
      }
    })

    const datesDoctor = dates.filter(
      d => parseInt(d.user_id) === parseInt(query.get('id'))
    )

    const handleDaySchedule = (day, schedule) => {
      const length = schedule.filter(s => s.type === 0)
      let scheduleList = []
      for (let i = 0; i < length.length; i++) {
        let start = moment(
          schedule.find(s => s.id === i + 1 && s.type === 0).time,
          'HH:mm:ss'
        )
        const end = moment(
          schedule.find(s => s.id === i + 1 && s.type === 1).time,
          'HH:mm:ss'
        )
        while (start.isSameOrBefore(end)) {
          scheduleList.push({
            startTime: start.format('HH:mm:ss'),
            endTime: start.add(30, 'm').format('HH:mm:ss'),
            daysOfWeek: [day],
            title: 'Disponible',
          })
          // start = start.add(30, 'm')
        }
        scheduleList = scheduleList.slice(0, scheduleList.length - 1)
      }

      return scheduleList
    }

    let eventsHours = [
      ...handleDaySchedule(
        1,
        doctorSchedules.filter(s => s.day === 1)
      ),
      ...handleDaySchedule(
        2,
        doctorSchedules.filter(s => s.day === 2)
      ),
      ...handleDaySchedule(
        3,
        doctorSchedules.filter(s => s.day === 3)
      ),
      ...handleDaySchedule(
        4,
        doctorSchedules.filter(s => s.day === 4)
      ),
      ...handleDaySchedule(
        5,
        doctorSchedules.filter(s => s.day === 5)
      ),
      ...handleDaySchedule(
        6,
        doctorSchedules.filter(s => s.day === 6)
      ),
      ...handleDaySchedule(
        7,
        doctorSchedules.filter(s => s.day === 7)
      ),
    ]

    datesDoctor.map(date => {
      eventsHours = eventsHours.filter(e => {
        return !(
          e.daysOfWeek[0] === moment(date.date).day() &&
          e.startTime === moment.utc(date.date).format('HH:mm:ss')
        )
      })
      return eventsHours
    })

    const handleSaveDate = () => {
      const data = {
        name,
        email: mail,
        phone,
        date: scheduleDate,
        user_id: query.get('id'),
      }
      if (
        name === undefined ||
        mail === undefined ||
        phone === undefined ||
        scheduleDate === ''
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Datos incompletos',
        })
      } else {
        props.create('dates', 'CREATE_DATE', data).then(() => {
          Swal.fire({
            title: 'Agendado con éxito',
            text: 'Por favor confirme su correo electrónico',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
          }).then(result => {
            if (result.isConfirmed) {
              props.history.push('/')
            }
          })
        })
      }
    }

    const handleChangeDate = date => {
      let calendarApi = refCalendar.current.getApi()
      calendarApi.gotoDate(date)
    }

    return (
      <div className="profile">
        <div
          className="profile__bar"
          style={{ backgroundColor: `${doctor.color1}` }}
        >
          <div className="profile__barInfo">
            <img
              src={
                doctor.image !== null
                  ? `${process.env.REACT_APP_FILES}/docs/doctors/${doctor.image}`
                  : img
              }
              alt={doctor.name}
            />
            <h5>{doctor.name}</h5>
            <span style={{ color: `${doctor.color3}` }}>
              {doctor.specialtie}
            </span>
          </div>
          <ul>
            <li
              style={{
                backgroundColor: view === 0 ? doctor.color2 : color,
                borderLeftColor: view === 0 ? doctor.color3 : border,
              }}
              onMouseEnter={() => {
                setColor(doctor.color2)
                setBorder(doctor.color3)
              }}
              onMouseLeave={() => {
                setColor(doctor.color1)
                setBorder(doctor.color)
              }}
              onClick={() => setView(0)}
            >
              <img src={ico1} alt="" />
              <div>Perfil Doctor</div>
            </li>
            <li
              style={{
                backgroundColor: view === 1 ? doctor.color2 : color2,
                borderLeftColor: view === 1 ? doctor.color3 : border2,
              }}
              onMouseEnter={() => {
                setColor2(doctor.color2)
                setBorder2(doctor.color3)
              }}
              onMouseLeave={() => {
                setColor2(doctor.color1)
                setBorder2(doctor.color)
              }}
              onClick={() => setView(1)}
            >
              <img src={ico2} alt="" />
              <div>Galería</div>
            </li>
            <li
              style={{
                backgroundColor: view === 2 ? doctor.color2 : color3,
                borderLeftColor: view === 2 ? doctor.color3 : border3,
              }}
              onMouseEnter={() => {
                setColor3(doctor.color2)
                setBorder3(doctor.color3)
              }}
              onMouseLeave={() => {
                setColor3(doctor.color1)
                setBorder3(doctor.color)
              }}
              onClick={() => setView(2)}
            >
              <img src={ico3} alt="" />
              <div>Preguntas Frecuentes</div>
            </li>
          </ul>
        </div>
        <div className="profile__container">
          <div className="profile__top">
            <TiArrowBack onClick={() => props.history.goBack()} />
            <div className="profile__socialMedia">
              <a href={doctor.web} target="_blank" rel="noopener noreferrer">
                <AiOutlineLink />
              </a>
              <a
                href={doctor.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
              <a
                href={doctor.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiInstagram />
              </a>
            </div>
          </div>
          {view === 0 ? (
            <div className="profile__wraper">
              <div className="profile__profile">
                <div className="profile__profileImage">
                  <img
                    src={
                      doctor.image !== null
                        ? `${process.env.REACT_APP_FILES}/docs/doctors/${doctor.image}`
                        : img
                    }
                    alt={doctor.name}
                  />
                  <div>
                    <Button className="button --info">
                      <a
                        href={` https://wa.me/52${doctor.phone.trim()}`}
                        target="blank"
                      >
                        Mensaje
                      </a>
                    </Button>
                    <Button className="button --success">
                      <a
                        href={` tel:52${
                          doctor.phone2
                            ? doctor.phone2.trim()
                            : doctor.phone.trim()
                        }`}
                        target="blank"
                      >
                        Llamar
                      </a>
                    </Button>
                  </div>
                </div>
                <div className="profile__profileInformation">
                  <h1>{doctor.name}</h1>
                  <h2>{doctor.specialtie}</h2>
                  <h3>Consultorio: {doctor.room}</h3>
                  <h4>Atención: {doctor.schodule}</h4>
                  <h5>
                    Contacto: {doctor.phone}
                    {doctor.phone2 ? ` / ${doctor.phone2}` : null}
                  </h5>
                  <div className="profile__profileServices">
                    <hr />
                    <h2>Servicios</h2>
                    <div>
                      {doctorServices.map(service => (
                        <span key={service.id}>{service.name}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile__dates">
                <h3>Citas</h3>
                {doctor.doctoralia === '' ? (
                  <>
                    <div className="profile__datesDay">
                      <div className="calendar">
                        <FullCalendar
                          plugins={[timeGridPlugin, interactionPlugin]}
                          eventClick={e => handleDateClick(e)}
                          locale={esLocale}
                          events={eventsHours}
                          allDaySlot={false}
                          ref={refCalendar}
                        />
                      </div>
                    </div>
                    <div className="profile__datesDay">
                      <div className="calendar">
                        <FullCalendar
                          plugins={[dayGridPlugin, interactionPlugin]}
                          dateClick={e => handleChangeDate(e.date)}
                          locale={esLocale}
                          events={events}
                        />
                      </div>
                    </div>
                    <div className="profile__datesDay">
                      <div className="profile__pickDate">
                        <h3>Seleccionar cita</h3>
                        {scheduleDate !== '' ? (
                          <h4>Fecha: {scheduleDate}</h4>
                        ) : null}
                        <input
                          className="input"
                          placeholder="Nombre"
                          type="text"
                          onInput={e => setName(e.target.value)}
                        />
                        <input
                          className="input"
                          placeholder="Teléfono"
                          type="tel"
                          defaultValue={preDate ? preDate.phone : null}
                          onInput={e => setPhone(e.target.value)}
                        />
                        <input
                          className="input"
                          placeholder="E-mail"
                          type="email"
                          defaultValue={preDate ? preDate.email : null}
                          onInput={e => setMail(e.target.value)}
                        />
                        <Button onClick={handleSaveDate}>Enviar</Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <a
                    href={doctor.doctoralia}
                    target="_blank"
                    className="profile__doctoralia"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={doctoralia}
                      alt="logo_doctoralia"
                      className="profile__doctoralia__logo"
                    />
                  </a>
                )}
              </div>
            </div>
          ) : null}
          {view === 1 ? (
            <div className="profile__wraper">
              <div className="profile__specialti">
                <div className="profile__specialti__description">
                  <img
                    src={
                      doctor.logo !== null
                        ? `${process.env.REACT_APP_FILES}/docs/logos/${doctor.logo}`
                        : logo
                    }
                    alt={doctor.name}
                  />
                  <div>
                    <Text>{doctor.description}</Text>
                  </div>
                </div>
                <div className="profile__specialti__imagen">
                  <img
                    src={
                      doctor.image !== null
                        ? `${process.env.REACT_APP_FILES}/docs/doctors/${doctor.image}`
                        : img
                    }
                    alt={doctor.name}
                  />
                  <div>
                    <Button className="button --info">Mensaje</Button>
                    <Button className="button --success">Llamar</Button>
                  </div>
                </div>
              </div>
              <div className="profile__gallery">
                <h3>Galería</h3>
                <Swiper
                  className="profile__gallery__slide"
                  spaceBetween={50}
                  slidesPerView={window.screen.width <= 600 ? 1 : 3}
                  slidesPerColumn={1}
                  navigation={true}
                  loop={true}
                >
                  {doctorImages.length > 0 ? (
                    doctorImages.map(image => (
                      <SwiperSlide
                        className="profile__gallery__item"
                        key={image.id}
                      >
                        <img
                          src={`${process.env.REACT_APP_FILES}/docs/galleries/${image.image}`}
                          alt=""
                        />
                      </SwiperSlide>
                    ))
                  ) : (
                    <SwiperSlide className="profile__gallery__item">
                      <img src={logo} alt="" />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
          ) : null}
          {view === 2 ? (
            <div className="profile__wraper">
              <div className="profile__qas">
                {doctorQA.length > 0 ? (
                  <h2>Preguntas frecuentes</h2>
                ) : (
                  <h2>Aún no hay preguntas frecuentes</h2>
                )}
                {doctorQA.map((qa, index) => (
                  <div className="profile__qas__item" key={qa.id}>
                    {index === 0 ? null : <hr />}
                    <div className="question">{qa.question}</div>
                    <div className="primary">{qa.answer}</div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

const mapStatetoProps = state => {
  return {
    doctor: state.reducerDoctors.doctor,
    preDate: state.reducerHome.preDate,
    doctorServices: state.reducerDoctors.doctorServices,
    doctorDates: state.reducerDoctors.doctorDates,
    doctorImages: state.reducerDoctors.doctorImages,
    doctorQA: state.reducerDoctors.doctorQA,
    doctorSchedules: state.reducerDoctors.doctorSchedules,
    doctorsInactiveDays: state.reducerDoctors.doctorsInactiveDays,
    dates: state.reducerDoctors.dates,
  }
}

const mapDispatchToProps = {
  getAll,
  get,
  create,
}

export default connect(mapStatetoProps, mapDispatchToProps)(Profile)
