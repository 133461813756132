import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { update } from '../../actions'
import { useLocation } from 'react-router-dom'
import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import Button from '../../components/Button'
import { BsCheckCircle } from 'react-icons/bs'
import './styles.scss'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Verify = props => {
  let query = useQuery()

  const id = query.get('id')

  useEffect(() => {
    props.update(`dates/verify/${id}`, 'UPDATE_DATE', id)
    // eslint-disable-next-line
  }, [])

  return (
    <main>
      <div className="verify">
        <BsCheckCircle />
        <Title>Su Email fue verificado</Title>
        <Subtitle>
          Le notificaremos cuando su cita haya sido confirmada
        </Subtitle>
        <Button onClick={() => props.history.push('/')}>Inicio</Button>
      </div>
    </main>
  )
}

const mapDispatchToProps = {
  update,
}

export default connect(null, mapDispatchToProps)(Verify)
