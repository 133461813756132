import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/Pruebadeesfuerzo.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Prueba de esfuerzo</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La prueba de esfuerzo es un estudio común que se utiliza para
            diagnosticar la enfermedad arterial coronaria. Permite ver cómo
            funciona el corazón durante el ejercicio. Las pruebas de esfuerzo
            también se denominan pruebas de esfuerzo físico, pruebas de
            tolerancia al ejercicio, ergometrías, electrocardiografías de
            esfuerzo o ECG de esfuerzo.
          </Text>
          <Text>
            Durante la prueba de esfuerzo, también puede realizarse una
            ecocardiografía (lo que se denomina «ecocardiografía de esfuerzo») o
            pueden inyectarse radioisótopos en la corriente sanguínea (lo que se
            denomina «prueba de esfuerzo nuclear»). Con estos estudios es
            posible obtener más información sobre la estructura y el flujo
            sanguíneo del corazón.
          </Text>

          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
