import React from 'react'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '../../../components/Button'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import './styles.scss'

import img1 from '../../../assets/static/services/urgencias.png'
import img2 from '../../../assets/static/services/farmacia.png'
import img3 from '../../../assets/static/services/hospitalizacion.png'
import img4 from '../../../assets/static/services/quirofano.png'
import img5 from '../../../assets/static/services/medicinadeltrabajo.png'
import img6 from '../../../assets/static/services/habitaciones.png'
import img7 from '../../../assets/static/services/nebu.png'
import img8 from '../../../assets/static/services/incubadoras.png'
import img9 from '../../../assets/static/services/terapiaFisica.jpeg'
import img10 from '../../../assets/static/services/Endoscopia.png'
import img11 from '../../../assets/static/services/cafeteria.jpeg'
import img12 from '../../../assets/static/services/colposcopia.jpeg'
import img13 from '../../../assets/static/services/terapiaFisica.png'
import img14 from '../../../assets/static/services/controlmaternidad.jpeg'
import img15 from '../../../assets/static/services/papanicolaou.jpeg'
import img16 from '../../../assets/static/services/biopsias-min.jpeg'
import img17 from '../../../assets/static/services/planificacionfamiliar.jpeg'
import img18 from '../../../assets/static/services/ultrasonidoObs.jpeg'
import img19 from '../../../assets/static/services/detecciondecancer.jpeg'
import img20 from '../../../assets/static/services/patologiadeMama.jpeg'
import img21 from '../../../assets/static/services/Encefalograma.jpeg'
import img22 from '../../../assets/static/services/MonitoreoHolter.jpeg'
import img23 from '../../../assets/static/services/Pruebadeesfuerzo.jpeg'
import img24 from '../../../assets/static/services/monitoreoambulat.jpeg'
import img25 from '../../../assets/static/services/Electromiografiae.jpeg'

const index = () => {
  return (
    <div className="services" id="services">
      <div className="services__container">
        <Title>Nuestros Servicios</Title>
        <Text>
          Nuestro Hospital cuenta con un amplio catálogo de servicios y
          especialidades de la medicina.
        </Text>
        <Swiper
          className="services__slider"
          spaceBetween={50}
          slidesPerView={window.screen.width <= 600 ? 1 : 3}
          slidesPerColumn={1}
          navigation={true}
          loop={true}
          autoplay={true}
        >
          <SwiperSlide className="services__item">
            <Link to="services/urgencias">
              <img className="services__image" src={img1} alt="" />
              <h5 className="services__subtitle">
                Urgencias y Rayos X 24 hrs.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/farmacia">
              <img className="services__image" src={img2} alt="" />
              <h5 className="services__subtitle">Farmacia</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/hospitalizacion">
              <img className="services__image" src={img3} alt="" />
              <h5 className="services__subtitle">Hospitalización</h5>
            </Link>
          </SwiperSlide>

          <SwiperSlide className="services__item">
            <Link to="services/quirofano">
              <img className="services__image" src={img4} alt="" />
              <h5 className="services__subtitle">
                Quirófano y sala de expulsión
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/medicina">
              <img className="services__image" src={img5} alt="" />
              <h5 className="services__subtitle">Medicina del trabajo.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/habitaciones">
              <img className="services__image" src={img6} alt="" />
              <h5 className="services__subtitle">
                Habitaciones sencillas y tipo suite.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/nebulizaciones">
              <img className="services__image" src={img7} alt="" />
              <h5 className="services__subtitle">Nebulizaciones</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/incubadoras">
              <img className="services__image" src={img8} alt="" />
              <h5 className="services__subtitle">Incubadoras y cuneros.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/terapia">
              <img className="services__image" src={img9} alt="" />
              <h5 className="services__subtitle">
                Terapia física y rehabilitación.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/endoscopia">
              <img className="services__image" src={img10} alt="" />
              <h5 className="services__subtitle">Endoscopía.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/cafeteria">
              <img className="services__image" src={img11} alt="" />
              <h5 className="services__subtitle">Cafetería.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/colposcopia">
              <img className="services__image" src={img12} alt="" />
              <h5 className="services__subtitle">Colposcopía.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/cuello-uterino">
              <img className="services__image" src={img13} alt="" />
              <h5 className="services__subtitle">Cirugía de cuello uterino.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/maternidad">
              <img className="services__image" src={img14} alt="" />
              <h5 className="services__subtitle">Control de maternidad.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/papanicolaou">
              <img className="services__image" src={img15} alt="" />
              <h5 className="services__subtitle">Papanicolaou.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/bipsias">
              <img className="services__image" src={img16} alt="" />
              <h5 className="services__subtitle">Biopsias.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/familiar">
              <img className="services__image" src={img17} alt="" />
              <h5 className="services__subtitle">Planificación familiar.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/ultrasonido">
              <img className="services__image" src={img18} alt="" />
              <h5 className="services__subtitle">Ultrasonido obstétrico.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/cervicouterino">
              <img className="services__image" src={img19} alt="" />
              <h5 className="services__subtitle">
                Detección de cáncer cervicouterino.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/patologia">
              <img className="services__image" src={img20} alt="" />
              <h5 className="services__subtitle">Patología de mama.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/encefalograma">
              <img className="services__image" src={img21} alt="" />
              <h5 className="services__subtitle">
                Encefalograma digital y mapeo cerebral.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/holter">
              <img className="services__image" src={img22} alt="" />
              <h5 className="services__subtitle">
                Monitoreo Holter de 24 Hrs.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/prueba-esfuerzo">
              <img className="services__image" src={img23} alt="" />
              <h5 className="services__subtitle">Prueba de esfuerzo.</h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/mapa">
              <img className="services__image" src={img24} alt="" />
              <h5 className="services__subtitle">
                Mapa (Monitoreo ambulatorio de presión arterial.
              </h5>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="services__item">
            <Link to="services/emg">
              <img className="services__image" src={img25} alt="" />
              <h5 className="services__subtitle">
                (EMG) Electromiografía de las 4 extremidades.
              </h5>
            </Link>
          </SwiperSlide>
        </Swiper>
        <Link to="services">
          <Button>Ver todos</Button>
        </Link>
      </div>
    </div>
  )
}

export default index
