import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/colposcopia.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Colposcopía</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Una colposcopia se usa para detectar células cancerosas o células
            anormales que se pueden volver cancerosas en el cuello uterino, la
            vagina o la vulva. Estas células anormales, en ocasiones, se
            denominan “tejido precanceroso”.
          </Text>
          <Text>
            En una colposcopia también se buscan otras afecciones médicas, como
            verrugas genitales o crecimientos no cancerosos llamados pólipos. Un
            instrumento especial llamado colposcopio ofrece al médico una vista
            iluminada y sumamente aumentada de los tejidos que componen el
            cuello uterino, la vagina y la vulva.
          </Text>
          <Text>
            El colposcopio se coloca cerca del cuerpo, pero no ingresa en el
            cuerpo.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
