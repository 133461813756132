import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/papanicolaou.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Papanicolaou</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La prueba de Papanicolaou, también conocida como “Pap” o citología
            vaginal, es muy importante porque permite detectar células anormales
            en el cuello uterino que podrían causar cáncer de cuello uterino
            (cáncer cervical). La prueba de Papanicolaou detecta cambios
            celulares provocados por el VPH, pero no detecta la presencia del
            VPH en sí.
          </Text>
          <Text>
            Esta prueba puede formar parte de tu chequeo de salud regular,
            examen pélvico o control ginecológico. Durante una prueba de
            Papanicolaou, el médico o enfermera te introduce un espéculo de
            metal o de plástico en la vagina. El espéculo se abre para separar
            las paredes de la vagina y así poder llegar al cuello uterino. A
            continuación utilizan un instrumento para tomar muestras, una
            espátula o un cepillo muy pequeños, con el que suavemente recogen
            células del cuello uterino. Las células se envían a un laboratorio
            para analizarlas.
          </Text>
          <Text>
            La prueba de Papanicolaou tarda apenas unos minutos. No es dolorosa,
            pero tal vez sientas algo de molestia o presión cuando la doctora o
            enfermero abre el espéculo una vez introducido en la vagina. También
            es posible que sientas un leve raspado cuando recogen células del
            cuello uterino.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
