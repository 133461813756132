import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/habitaciones.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Habitaciones.</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Nuestro servicio de hospitalización cuenta con cómodas habitaciones
            privadas, las cuales son asignadas según la preferencia del
            paciente. La atención en habitaciones dispone de un equipo humano
            disciplinado, atento a brindar, con oportunidad y amabilidad, la
            atención médica y nutricional requerida por el paciente durante su
            permanencia en el hospital.
          </Text>
          <Text>
            Además, se cuenta con el soporte de todos los servicios de apoyo
            diagnóstico y terapéutico del hospital. Proporcionando las
            condiciones adecuadas para la pronta recuperación de los pacientes.
          </Text>
          <Text>
            Consideramos que la correcta alimentación de nuestros pacientes
            durante su recuperación es una parte muy importante del tratamiento.
            El hospital cuenta con un servicio de profesionales en nutrición que
            elabora variados y excelentes platos según las indicaciones médicas
            para cada paciente.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
