import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/medicinadeltrabajo.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Medicina del trabajo</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La MEDICINA DEL TRABAJO es la especialidad médica que se dedica al
            estudio de las enfermedades y los accidentes que se producen por
            causa o consecuencia de la actividad laboral, así como las medidas
            de prevención que deben ser adoptadas para evitarlas o aminorar sus
            consecuencias.
          </Text>
          <Text>
            La Medicina del Trabajo es una especialidad médica que tiene como
            objetivos principales el aprendizaje de los conocimientos, técnicas
            y habilidades relacionadas con:
          </Text>
          <Text>
            a) La prevención del riesgo que puede afectar a la salud humana como
            consecuencia de las circunstancias y condiciones de trabajo.
          </Text>
          <Text>
            b) Las patologías derivadas del trabajo, en sus tres grandes
            vertientes de accidentes de trabajo, enfermedades profesionales y
            enfermedades relacionadas con el trabajo y, en su caso, la adopción
            de las medidas necesarias de carácter preventivo, diagnóstico,
            terapéutico y rehabilitador.{' '}
          </Text>
          <Text>
            c) La valoración pericial de las consecuencias que tiene la
            patología laboral para la salud humana, en particular en el caso de
            las incapacidades.
          </Text>
          <Text>
            d) Las organizaciones empresariales y sanitarias con el fin de
            conocer su tipología a fin de gestionar con mayor calidad y
            eficiencia la Salud Laboral.
          </Text>
          <Text>
            e) La historia natural de la salud y la enfermedad en general, y en
            particular, el estudio de la salud de los individuos y grupos en sus
            relaciones con el medio laboral y la Promoción de la Salud en dicho
            ámbito.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
