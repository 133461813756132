import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom'

import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import Layout from '../layout'

import Facilities from '../pages/Facilities'
import Doctors from '../pages/Doctors'

import Services from '../pages/Services'
import Hospitalizacion from '../pages/Services/Hospitalizacion'
import Urgencias from '../pages/Services/Urgencias'
import Farmacia from '../pages/Services/Farmacia'
import Quirofano from '../pages/Services/Quirofano'
import MedicinaTrabajo from '../pages/Services/MedicinaTrabajo'
import Habitaciones from '../pages/Services/Habitaciones'
import Nebulizaciones from '../pages/Services/Nebulizaciones'
import Incubadoras from '../pages/Services/Incubadoras'
import TerapiaFisica from '../pages/Services/TerapiaFisica'
import Endoscopia from '../pages/Services/Endoscopia'
import Cafeteria from '../pages/Services/Cafeteria'
import Colposcopia from '../pages/Services/Colposcopia'
import CirugiaUterino from '../pages/Services/CirugiaUterino'
import ControlMaternidad from '../pages/Services/ControlMaternidad'
import Papanicolaou from '../pages/Services/Papanicolaou'
import Biopsias from '../pages/Services/Biopsias'
import PlanificacionFamiliar from '../pages/Services/PlanificacionFamiliar'
import Ultrasonido from '../pages/Services/Ultrasonido'
import DeteccionCancer from '../pages/Services/DeteccionCancer'
import Patologia from '../pages/Services/Patologia'
import Encefalograma from '../pages/Services/Encefalograma'
import Monitoreo from '../pages/Services/Monitoreo'
import PruebaEsfuerzo from '../pages/Services/PruebaEsfuerzo'
import Mapa from '../pages/Services/Mapa'
import Emg from '../pages/Services/Emg'
import Bioimpedancia from '../pages/Services/Bioimpedancia'
import PsicologiaClinica from '../pages/Services/PsicologiaClinica'

import Profile from '../pages/Profile'
import Laboratory from '../pages/Laboratory'
import Verify from '../pages/Verify'

function _ScrollToTop(props) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

const App = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/facilities" component={Facilities} />
          <Route exact path="/services" component={Services} />
          <Route
            exact
            path="/services/hospitalizacion"
            component={Hospitalizacion}
          />
          <Route exact path="/services/farmacia" component={Farmacia} />
          <Route exact path="/services/quirofano" component={Quirofano} />
          <Route exact path="/services/medicina" component={MedicinaTrabajo} />
          <Route exact path="/services/habitaciones" component={Habitaciones} />
          <Route
            exact
            path="/services/nebulizaciones"
            component={Nebulizaciones}
          />
          <Route exact path="/services/incubadoras" component={Incubadoras} />
          <Route exact path="/services/terapia" component={TerapiaFisica} />
          <Route exact path="/services/endoscopia" component={Endoscopia} />
          <Route exact path="/services/cafeteria" component={Cafeteria} />
          <Route exact path="/services/colposcopia" component={Colposcopia} />
          <Route
            exact
            path="/services/cuello-uterino"
            component={CirugiaUterino}
          />
          <Route
            exact
            path="/services/maternidad"
            component={ControlMaternidad}
          />
          <Route exact path="/services/papanicolaou" component={Papanicolaou} />
          <Route exact path="/services/biopsias" component={Biopsias} />
          <Route
            exact
            path="/services/familiar"
            component={PlanificacionFamiliar}
          />
          <Route exact path="/services/ultrasonido" component={Ultrasonido} />
          <Route
            exact
            path="/services/cervicouterino"
            component={DeteccionCancer}
          />
          <Route exact path="/services/patologia" component={Patologia} />
          <Route
            exact
            path="/services/encefalograma"
            component={Encefalograma}
          />
          <Route exact path="/services/holter" component={Monitoreo} />
          <Route
            exact
            path="/services/prueba-esfuerzo"
            component={PruebaEsfuerzo}
          />
          <Route
            exact
            path="/services/bio"
            component={Bioimpedancia}
          />
          <Route
            exact
            path="/services/psicologia-clinica"
            component={PsicologiaClinica}
          />
          <Route exact path="/services/mapa" component={Mapa} />
          <Route exact path="/services/emg" component={Emg} />

          <Route exact path="/services/urgencias" component={Urgencias} />
          <Route exact path="/specialties" component={Facilities} />
          <Route exact path="/doctors" component={Doctors} />
          <Route exact path="/laboratory" component={Laboratory} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/verify" component={Verify} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </ScrollToTop>
  </BrowserRouter>
)

export default App
