import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/detecciondecancer.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">
          Detección de cáncer cervicouterino.
        </Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Los exámenes de detección son la búsqueda de cáncer antes de que la
            persona tenga cualquier síntoma. Esto puede ayudar a encontrar el
            cáncer en un estadio temprano. Cuando el tejido anormal o el cáncer
            se encuentran temprano, puede ser más fácil tratarlos. Cuando
            aparecen los síntomas, el cáncer se puede haber empezado a diseminar
          </Text>
          <Text>
            Los científicos tratan de entender mejor quiénes tienen más
            posibilidades de enfermarse de ciertos tipos de cáncer. También
            estudian lo que hacen las personas y las cosas que las rodean para
            ver si estas causan cáncer. Esta información ayuda a los médicos a
            recomendar quién se debe someter a los exámenes de detección del
            cáncer, qué pruebas se deben usar y con qué frecuencia deben
            realizarse.
          </Text>
          <Text>
            Es importante recordar que el médico no piensa necesariamente que
            usted tiene cáncer si le indica un examen de detección, ya que estos
            se realizan cuando usted no tiene síntomas.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
