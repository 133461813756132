import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/patologiadeMama.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Patología de mama</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Las pruebas de detección de cáncer de mama pueden ayudar a detectar
            el cáncer de mama temprano, antes de que usted note cualquier
            síntoma.
          </Text>
          <Text>
            En muchos casos, detectar el cáncer de mama temprano hace que sea
            más fácil tratarlo o curarlo. Pero las pruebas de detección también
            conllevan riesgos, como por ejemplo pasar por alto signos de cáncer.
          </Text>
          <Text>
            El momento para comenzar las pruebas de detección puede depender de
            su edad y sus factores de riesgo.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
