import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/MonitoreoHolter.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Monitoreo Holter de 24 Hrs.</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Es una máquina que registra los ritmos cardíacos en forma continua.
            Se lleva puesto de 24 a 48 horas durante la actividad normal.
          </Text>
          <Text>
            Los electrodos (pequeños parches conductores) se pegan en el pecho.
            Se conectan por medio de alambres a un pequeño monitor de registro.
            Usted carga el monitor Holter en un bolsillo o en una bolsa que se
            lleva puesta alrededor del cuello o la cintura. El monitor funciona
            con baterías.
          </Text>
          <Text>
            Mientras usted lleva puesto el monitor, éste registra la actividad
            eléctrica del corazón.
            <ul>
              <li>
                Lleve un registro diario de las actividades que realiza mientras
                está usando el monitor y cómo se siente.
              </li>
              <li>
                Después de 24 a 48 horas, debe devolver el monitor al
                consultorio de su proveedor de atención médica.
              </li>
              <li>
                El proveedor observará los registros y mirará si ha habido algún
                ritmo cardíaco anormal.
              </li>
            </ul>
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
