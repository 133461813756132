import React from 'react'
import './styles.scss'
import Title from '../../components/Title'
import banner from '../../assets/static/facilities/BannerInstala.png'
import SimpleReactLigthbox, { SRLWrapper } from 'simple-react-lightbox'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Scrollbar } from 'swiper/core'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

import img1 from '../../assets/static/facilities/001.png'
import img2 from '../../assets/static/facilities/002.png'
import img3 from '../../assets/static/facilities/003.png'
import img4 from '../../assets/static/facilities/004.png'
import img5 from '../../assets/static/facilities/005.png'
import img6 from '../../assets/static/facilities/006.png'
import img7 from '../../assets/static/facilities/007.png'
import img8 from '../../assets/static/facilities/m01-min.jpeg'
import img9 from '../../assets/static/facilities/m02-min.jpeg'
import img10 from '../../assets/static/facilities/m03-min.jpeg'
import img11 from '../../assets/static/facilities/m04-min.jpeg'
import img12 from '../../assets/static/facilities/m05-min.jpeg'
import img13 from '../../assets/static/facilities/m06-min.jpeg'
import img14 from '../../assets/static/facilities/m07-min.jpeg'
import img15 from '../../assets/static/facilities/m08-min.jpeg'
import img16 from '../../assets/static/facilities/m09-min.jpeg'
import img17 from '../../assets/static/facilities/m10-min.jpeg'
import img18 from '../../assets/static/facilities/m11-min.jpeg'
import img19 from '../../assets/static/facilities/m12-min.jpeg'
import img20 from '../../assets/static/facilities/m13-min.jpeg'
import img21 from '../../assets/static/facilities/m14-min.jpeg'

// install Swiper modules
SwiperCore.use([Scrollbar])

const Facilities = () => {
  return (
    <main className="facilities">
      <div
        className="facilities__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Title className="title --white2">Instalaciones</Title>
      </div>
      <div className="facilities__container">
        <SimpleReactLigthbox>
          <SRLWrapper>
            <Swiper
              scrollbar={{
                hide: true,
              }}
              className="facilities__slider"
              spaceBetween={50}
              slidesPerView={1}
              slidesPerColumn={1}
              navigation={true}
              loop={true}
            >
              <SwiperSlide className="facilities__item">
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
                <img src={img5} alt="" />
                <img src={img6} alt="" />
                <img src={img7} alt="" />
              </SwiperSlide>
              <SwiperSlide className="facilities__item">
                <img src={img8} alt="" />
                <img src={img9} alt="" />
                <img src={img10} alt="" />
                <img src={img11} alt="" />
                <img src={img12} alt="" />
                <img src={img13} alt="" />
                <img src={img14} alt="" />
              </SwiperSlide>
              <SwiperSlide className="facilities__item">
                <img src={img15} alt="" />
                <img src={img16} alt="" />
                <img src={img17} alt="" />
                <img src={img18} alt="" />
                <img src={img19} alt="" />
                <img src={img20} alt="" />
                <img src={img21} alt="" />
              </SwiperSlide>
            </Swiper>
          </SRLWrapper>
        </SimpleReactLigthbox>
      </div>
    </main>
  )
}

export default Facilities
