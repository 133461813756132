import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import Title from '../../components/Title'
import banner from '../../assets/static/2.png'
import Loading from '../../components/Loading'
import img from '../../assets/static/LogoAnalisis.svg'
import Modal from '../../components/Modal'
// import Whatsapp from '../../components/Whatsapp'
import './styles.scss'

const Laboratory = props => {
  const { tests, letters, getAll, testsRequest } = props
  const [active, setActive] = useState('todos')
  const [search, setSearch] = useState('')
  const [request, setRequest] = useState([])
  const [classModal, setClassModal] = useState('--hidden')

  useEffect(() => {
    getAll('studies', 'GET_TESTS').then(() => {
      getAll('studies/full', 'GET_TESTS_REQUEST')
    })
    // eslint-disable-next-line
  }, [])

  if (tests && testsRequest) {
    const handleModal = id => {
      const filtered = testsRequest.filter(
        test => parseInt(test.study_id) === parseInt(id)
      )
      setRequest(filtered)
      setClassModal('--show')
    }

    const testSearch = tests.filter(test =>
      test.name.toLowerCase().includes(search.toLowerCase())
    )

    let testsFiltered

    if (active !== 'todos') {
      testsFiltered = testSearch.filter(
        test => test.name[0].toLowerCase() === active
      )
    } else {
      testsFiltered = testSearch
    }
    return (
      <div className="laboratory">
        <div
          className="laboratory__banner"
          style={{
            backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <Title className="title --white2">Análisis Clínicos</Title>
          <input type="text" onChange={e => setSearch(e.target.value)} />
        </div>
        <div className="laboratory__container">
          <img src={img} alt="BioQuim-logo" className="laboratory__logo" />
          <div className="laboratory__data">
            <h5 style={{ textAlign: 'center' }}>
              Lunes a Sábado de 8:00 am a 3:00 pm
            </h5>
            <h5 style={{ textAlign: 'center' }}>
              <a href="mailto:bioquim305@hotmail.com">Email: bioquim305@hotmail.com</a>
            </h5>
            <h5 style={{ textAlign: 'center' }}>
              <a href="tel:2414171917">Tel: 241 417 1917 Ext. 120</a>
            </h5>
          </div>
          <div className="laboratory__filter">
            {letters.map(letter => (
              <span
                className={`laboratory__letter ${
                  active === letter.id ? '--active' : ''
                }`}
                key={letter.id}
                onClick={() => setActive(letter.id)}
              >
                {letter.id}
              </span>
            ))}
          </div>
          <div className="laboratory__list">
            <ul>
              {testsFiltered && testsFiltered.length > 0 ? (
                testsFiltered.map(test => (
                  <li key={test.id} onClick={() => handleModal(test.id)}>
                    {test.name}
                  </li>
                ))
              ) : (
                <li>No hay resultados</li>
              )}
            </ul>
          </div>
        </div>
        {/* <Whatsapp number="" /> */}
        <Modal
          requeriments={request}
          className={classModal}
          onClick={() => setClassModal('--hidden')}
        />
      </div>
    )
  } else {
    return <Loading />
  }
}

const mapStatetoProps = state => {
  return {
    tests: state.reducerTests.tests,
    testsRequest: state.reducerTests.testsRequest,
    letters: state.reducerTests.letters,
  }
}

const mapDispatchToProps = {
  getAll,
}

export default connect(mapStatetoProps, mapDispatchToProps)(Laboratory)
