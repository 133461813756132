import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/Encefalograma.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">
          {' '}
          Encefalograma digital y mapeo cerebral.
        </Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            El cerebro está formado por miles de millones de microscópicas
            células llamadas neuronas que se comunican entre ellas mediante
            impulsos eléctricos (potenciales de acción). Los impulsos eléctricos
            producidos por las neuronas generan unos específicos patrones de la
            actividad eléctrica cerebral que se denominan ondas cerebrales y que
            se relacionan con diferentes estados mentales y conductas. Algunas
            de estas ondas son más rápidas, otras más lentas, y se conocen como
            Delta, Theta, Alpha, Beta y Gamma.
          </Text>
          <Text>
            Las ondas cerebrales se pueden evaluar por medio de la técnica del
            Electroencefalograma (EEG).
          </Text>
          <Text>
            Es una técnica muy apta y versátil para la evaluación de sujetos que
            no toleran espacios estrechos y claustrofóbicos, como por ejemplo
            pueden ser los de la resonancia magnética.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
