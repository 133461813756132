import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/planificacionfamiliar.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Planificación familiar.</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La planificación familiar consiste principalmente en una serie de
            prácticas que se utilizan con el objetivo de controlar la
            reproducción con el uso de métodos anticonceptivos durante el acto
            sexual. Gracias a la planificación familiar, las personas pueden
            controlar el número de hijos que tendrán.
          </Text>
          <Text>
            La planificación familiar permite la elección del método
            anticonceptivo para cada situación. El objetivo final de la
            planificación familiar es mejorar la salud materna y de los hijos en
            general.
          </Text>

          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
