import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/incubadoras.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Incubadoras y cuneros.</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            Una incubadora pediátrica provee un ambiente cerrado, controlado,
            que calienta al bebé al hacer circular aire calentado sobre la piel.
            Entonces, el calor se absorbe dentro del cuerpo mediante conducción
            tisular y convección sanguínea. Idealmente, ambas temperaturas, la
            de la piel y la del centro del cuerpo, deben mantenerse con apenas
            pequeñas variaciones. Las incubadoras logran un mejor control de la
            temperatura que los calefactores por radiación térmica (unidades
            calefactoras cenitales), las cuales pueden calentar en exceso o en
            defecto al bebé. No obstante, el pequeño dentro de la incubadora es
            menos accesible para quien lo cuida.
          </Text>
          <Text>
            Cuando el bebé nace, sus temperaturas cutánea y central tienden a
            caer de forma significativa, a causa de la pérdida de calor por
            conducción (pérdida de calor hacia las superficies más frías que se
            encuentran en contacto directo con el bebé), convección (pérdida de
            calor hacia el aire que pasa sobre el bebé), radiación (pérdida de
            calor hacia objetos más fríos, que no se encuentran en contacto
            directo con el bebé) y evaporación de agua (pérdida de calor desde
            los pulmones y la superficie de la piel del bebé).
          </Text>
          <Text>
            Mientras que los neonatos a término regulan naturalmente su
            temperatura corporal hasta cierto punto, los prematuros tienen una
            piel más delgada, lo cual hace que los vasos sanguíneos
            superficiales pierdan calor más fácilmente hacia el ambiente; una
            proporción más grande de superficie corporal a volumen, que da lugar
            a una mayor pérdida de calor por radiación y convección, y casi nada
            de grasa subcutánea, bien sea para metabolizarla y producir calor, o
            como aislante. El estrés prolongado por frío en neonatos puede
            causar deprivación de oxígeno, hipoglucemia, acidosis metabólica y
            agotamiento rápido de las reservas de glucógeno. La conservación de
            la energía proporcionada por el soporte térmico es fundamental.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
