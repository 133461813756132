import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/ultrasonidoObs.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Ultrasonido obstétrico</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            El ultrasonido obstétrico utiliza ondas sonoras para producir
            imágenes de un bebé (embrión o feto) que está dentro de una mujer
            embarazada, como así también del útero y los ovarios de la madre. No
            utiliza radiación ionizante, no tiene efectos nocivos conocidos, y
            es el método preferido para la vigilancia de las mujeres embarazadas
            y sus bebés que aún no han nacido.
          </Text>
          <Text>
            Un estudio de ultrasonido Doppler (una técnica que evalúa el flujo
            de sangre en el cordón umbilical, en el feto o en la placenta)
            podría formar parte de este examen.
          </Text>
          <Text>
            Este procedimiento no requiere de ninguna de preparación especial.
            Debido a que solamente se necesita exponer la zona del abdomen
            inferior, le convendría llevar una vestimenta holgada, de dos
            piezas. Deje las joyas en casa.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
