import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Text from '../../components/Text'
import { Link } from 'react-router-dom'
import { getAll } from '../../actions'
import Button from '../../components/Button'
import { FaPhoneAlt } from 'react-icons/fa'
import img from '../../assets/static/demo.png'
import Title from '../../components/Title'
import banner from '../../assets/static/facilities/BannerInstala.png'
import Loading from '../../components/Loading'
import './styles.scss'

const Doctors = props => {
  const { getAll, doctors } = props

  useEffect(() => {
    getAll('doctors', 'GET_DOCTORS')
    // eslint-disable-next-line
  }, [])
  if (doctors) {
    return (
      <div className="doctorsPage">
        <div
          className="doctorsPage__banner"
          style={{
            backgroundImage: `linear-gradient(
           rgba(0, 0, 0, .4),
           rgba(0, 0, 0, .4)
         ), url(${banner})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Title className="title --white2">Doctores</Title>
        </div>
        <div className="doctorsPage__container">
          {doctors
            ? doctors.map(doctor => (
                <div className="doctorsPage__item" key={doctor.id}>
                  <div className="doctorsPage__itemProfile">
                    <img
                      className="doctorsPage__image"
                      src={
                        doctor.image !== null
                          ? `${process.env.REACT_APP_FILES}/docs/doctors/${doctor.image}`
                          : img
                      }
                      alt=""
                    />
                    <div className="doctorsPage__itemText">
                      <h5 className="doctorsPage__name">{doctor.name}</h5>
                      <Text className="text doctorsPage__specialty">
                        {doctor.specialty}
                      </Text>
                      <h5 className="doctorsPage__phone">
                        <FaPhoneAlt />
                        {doctor.phone}
                      </h5>
                    </div>
                  </div>
                  <Text>{doctor.description}</Text>
                  <Link to={`profile?id=${doctor.user_id}`}>
                    <Button>Ver perfil</Button>
                  </Link>
                </div>
              ))
            : null}
        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    doctors: state.reducerDoctors.doctors,
  }
}

const mapDispatchToProps = {
  getAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Doctors)
