import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/Endoscopia.png'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">Endoscopía</Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            La endoscopia es la técnica que permite (utilizando un tubo de fibra
            óptica largo, delgado y flexible) obtener imágenes del esófago,
            estómago e intestino. A través del trabajo de gastroenterólogos,
            cirujanos digestivos y pediatras ofrecemos servicios que usan
            tecnología de punta para el diagnóstico endoscópico. Algunos
            padecimientos que atendemos son:
          </Text>

          <Text>
            <ul>
              <li>Cálculos biliares</li>
              <li>Gastritis</li>
              <li>Hemorroides</li>
              <li>Esofágitis</li>
              <li>Hernia hiatal</li>
              <li>Colitis</li>
              <li>Tumores</li>
              <li>Ulceras, etc.</li>
            </ul>
          </Text>

          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
