import React from 'react'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import banner from '../../../assets/static/services/BannerNueServ.png'
import img1 from '../../../assets/static/services/monitoreoambulat.jpeg'
import '../styles.scss'
import { Link } from 'react-router-dom'

const Hospitalizacion = () => {
  return (
    <div className="servicesPage">
      <div
        className="servicesPage__banner"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%',
        }}
      >
        <Title className="title --white2">
          Mapa (Monitoreo ambulatorio de presión arterial).
        </Title>
      </div>

      <div className="servicesPage__details">
        <img src={img1} alt="" />
        <div className="servicesPage__paragraph">
          <Text>
            El MAPA (monitoreo ambulatoria de la presión arterial), también
            llamado holter de tensión arterial, es un método técnico no invasivo
            que pretende obtener una medición de la presión arterial durante un
            período de tiempo determinado, generalmente 24 horas, de tal forma
            que los datos de tensión arterial recogidos puedan ser
            posteriormente analizados por su médico.
          </Text>
          <Text>
            La prueba se realiza de forma ambulatoria. El paciente debe acudir a
            nuestro servicio para la colocación del aparato holter. Éste consta
            de un brazalete que contiene un sensor que detecta los pulsos
            arteriales durante la toma de la tensión y que va conectado con un
            aparato de registro que graba los datos obtenidos.
          </Text>
          <Text>
            El brazalete está diseñado para inflarse de forma periódica según
            haya sido programado, generalmente cada 15-20 minutos en período
            diurno y por la noche cada 20-30 min.
          </Text>
          <Text>
            Tras inflarse el brazalete irá poco a poco desinflándose y
            registrando mediante el sensor los pulsos arteriales que permiten
            obtener los datos de la tensión arterial. El registro obtenido es
            trasmitido a un aparato grabador que almacenará los datos.
          </Text>
          <Link to="/doctors">
            <Button className="button --secondary">Agendar cita</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hospitalizacion
