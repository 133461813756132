import React from 'react'
import { Link } from 'react-router-dom'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import img1 from '../../../assets/static/foto01.png'
import './styles.scss'

const Specialties = () => {
  return (
    <div className="specialties" id="specialties">
      <div
        className="specialties__left"
        style={{
          backgroundImage: `linear-gradient(
         rgba(0, 0, 0, .4),
         rgba(0, 0, 0, .4)
       ), url(${img1})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Title className="title --white">
          diagnóstico rápido, preciso e indoloro
        </Title>
        <Text className="text --white">
          Nuestro Hospital cuenta con un amplio personal, áreas y especialidades
          de la medicina.
        </Text>
        <Link to="/services">
          <Button className="button --white">Servicios</Button>
        </Link>
      </div>
      <div className="specialties__right">
        <Title>Especialidades</Title>
        <Text>
          La amplia gama de servicios que ofrece el Hospital San Francisco de
          Asís, sus modernas instalaciones y equipo médico especializado, es
          ampliamente reconocida en el estado de Tlaxcala, siendo en la
          actualidad reconocido como el mejor hospital privado en el estado.
        </Text>
        <div className="specialties__list">
          <ul>
            <li>GINECOLOGÍA</li>
            <li>PEDIATRÍA</li>
            <li>MEDICINA INTERNA</li>
            <li>CIRUGÍA GENERAL</li>
            <li>OTORRINOLARINGOLOGÍA</li>
            <li>ANESTESIOLOGÍA</li>
            <li>UROLOGÍA</li>
            <li>ODONTOLOGÍA</li>
            <li>OFTALMOLOGÍA</li>
          </ul>
          <ul>
            <li>ORTOPEDIA Y TRAUMATOLOGÍA</li>
            <li>CARDIOLOGÍA</li>
            <li>NUTRICIÓN</li>
            <li>PSICOLOGÍA</li>
            <li>MAXILOFACIAL</li>
            <li>GASTROENTEROLOGÍA</li>
            <li>ENDOSCOPÍA</li>
            <li>PSIQUIATRÍA</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Specialties
