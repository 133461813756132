import React from 'react'
import { Link } from 'react-router-dom'
import Carousel from './Carousel'
import About from './About'
import Services from './Services'
import Specialties from './Specialties'
import Doctors from './Doctors'
import Contact from './Contact'
import './styles.scss'
import ico1 from '../../assets/static/icodoc.svg'
import ico2 from '../../assets/static/IcoHosp.svg'
import ico3 from '../../assets/static/IcoAcceso.svg'
import ico4 from '../../assets/static/IcoUrgencias.svg'
import GoogleMap from 'google-map-react'
import { FaRegHospital } from 'react-icons/fa'

const Home = props => {
  const mapSettings = {
    center: {
      lat: 19.419182,
      lng: -98.144622,
    },
    zoom: 18,
  }

  const Pin = () => <FaRegHospital className="pin" />

  const handleGoToMaps = () => {
    window.open(
      'https://www.google.com/maps/place/Hospital+san+francisco+de+asis/@19.4191093,-98.1446836,21z/data=!3m1!5s0x85d020252c4ca8df:0x7947b4ea96b1ef8c!4m5!3m4!1s0x85d0211bb717f1af:0x9c5daf8ad32bd146!8m2!3d19.419177!4d-98.1445425',
      '_blank'
    )
  }

  return (
    <main id="inicio">
      <Carousel />
      <div className="floatBar">
        <div className="floatBar__icon">
          <Link to="doctors">
            <img src={ico1} alt="" />
            <span>Doctores</span>
          </Link>
        </div>
        <div className="floatBar__icon">
          <Link to="facilities">
            <img src={ico2} alt="" />
            <span>Hospital</span>
          </Link>
        </div>
        <div className="floatBar__icon">
          <a href="tel:+522414171917">
            <img src={ico3} alt="" />
            <span>Urgencias</span>
          </a>
        </div>
        <div className="floatBar__icon">
          <a href="https://hsfa-dashboard.vercel.app" target="blank">
            <img src={ico4} alt="" />
            <span>Acceso</span>
          </a>
        </div>
      </div>
      <About />
      <Services />
      <Specialties />
      <Doctors />
      <div className="map">
        <GoogleMap
          bootstrapURLKeys={{ key: 'AIzaSyAeOLaJsbTpcoK-OLc7ORwk0x7-ooZwN8A' }}
          center={mapSettings.center}
          zoom={mapSettings.zoom}
          onClick={() => console.log('Click')}
          onChildClick={() => handleGoToMaps()}
        >
          <Pin
            lat={19.419182}
            lng={-98.144622}
            text="Hospital San Francisco de Asis"
          />
        </GoogleMap>
      </div>
      <Contact />
    </main>
  )
}

export default Home
